#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout .site-layout-background {
  background: #fff;
  border-radius: 10px;
}

.ant-layout {
  background-image: url('https://www.fleetintelligence.se/assets/img/Portify_login_bg2.jpg');
  min-height: 1000px;
  width: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.loader-quote {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}